import { createStore } from 'vuex'
import axios from 'axios'
import moment from 'moment'
moment.locale('it')

// ---INITIAL STATE
const getDefaultState = () => {
  return {
    user: null,
    mail: null
  }
}

let loaded = false

const store = createStore({
  state: getDefaultState(),

  // ---GETTERS
  getters: {
    isAuthenticated: state => !!state.user,
    stateUser: state => state.user,
    stateMail: state => state.mail
  },

  // ---MUTATIONS
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setUser (state, user) {
      state.user = user
      if (user) localStorage.setItem(process.env.VUE_APP_NAME + 'store', JSON.stringify(user))
      else localStorage.removeItem(process.env.VUE_APP_NAME + 'store')
    },
    setUserMail (state, mail) {
      state.mail = mail
      if (mail) localStorage.setItem(process.env.VUE_APP_NAME + 'currentMail', mail)
      else localStorage.removeItem(process.env.VUE_APP_NAME + 'currentMail')
    },
    initializeStore (state) {
      if (!loaded) {
        if (localStorage.getItem(process.env.VUE_APP_NAME + 'store')) {
          state.user = JSON.parse(localStorage.getItem(process.env.VUE_APP_NAME + 'store'))
          state.mail = localStorage.getItem(process.env.VUE_APP_NAME + 'currentMail')
        }
        loaded = true
      }
    }
  },

  // ---ACTIONS
  actions: {
    resetStore ({ commit }) {
      commit('resetState')
    },
    // --Get logs
    async getHistory ({ state }, { phone, desc, offset, limit, categories }) {
      const data = state.user
      const off = 0 // offset || 0
      const lim = 100000 // limit || 10000
      let rsp
      if (data) {
        const Authorization = data.token_type + ' ' + data.access_token
        rsp = await axios.get('/logs/?skip=' + off + '&limit=' + lim, {
          headers: {
            Authorization
          }
        })
          .then((rsp) => {
            let logs = rsp.data
            // console.log(logs)
            // -Filter by PHONE
            if (phone) {
              logs = logs.filter((el) => {
                const currentNumber = el.extra_data.phone_number
                const thirtynined = '+39' + phone
                const thirtyninedCurrent = '+39' + currentNumber
                return currentNumber === phone || currentNumber === thirtynined || phone === thirtyninedCurrent
              })
            }

            if (desc) {
              logs = logs.filter((el) => {
                const currentDesc = el.description
                return currentDesc.includes(desc)
              })
            }

            for (let i = 0; i < logs.length; i++) {
              // const offset = moment().utcOffset()
              // let temp
              // if (logs[i].extra_data.timestamp) {
              //   temp = moment.parseZone(logs[i].extra_data.timestamp).utcOffset(offset).format('lll')
              // } else {
              //   temp = moment.parseZone(logs[i].created_at).utcOffset(offset).format('lll')
              // }
              if (logs[i].extra_data.timestamp) {
                logs[i].created_at = logs[i].extra_data.timestamp
              }
            }

            logs.sort((a, b) => {
              return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            })

            for (let i = 0; i < logs.length; i++) {
              const offset = moment().utcOffset()
              const temp = moment.parseZone(logs[i].created_at).utcOffset(offset).format('lll')
              logs[i].created_at = temp
            }

            if (categories && categories.length) {
              logs = logs.filter((el, id) => {
                return categories.includes(el.category)
              })
            }

            const returnedValues = []
            for (let i = offset; i < limit; i++) {
              if (logs[i]) returnedValues.push(logs[i])
            }
            return returnedValues
          })
          .catch((e) => {
            console.error('HISTORY', e)
            return false
          })
      } else {
        console.error('NOT AUTHENTICATED', store.user)
        rsp = false
      }
      return rsp
    },
    // --Send Mail Report
    async sendMail ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/utils/export-users/', payload, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          // console.log('---SEND MAIL', rsp)
          return rsp
        })
        .catch((e) => {
          console.error('SEND MAIL', e)
          return false
        })
      return rsp
    },
    // --Campaign Stats
    async getStats ({ commit, state }) {
      const data = state.user
      if (data) {
        const Authorization = data.token_type + ' ' + data.access_token
        return await axios.get('/utils/stats', {
          headers: {
            Authorization
          }
        })
          .then((rsp) => {
            return rsp.data
          })
          .catch(async (e) => {
            console.error('STATS', e)
            commit('setUser', null)
            commit('setUserMail', null)
            return false
          })
      }
    },
    // --All Users
    async getUsers ({ state }, { phone, desc, offset, limit, categories }) {
      const data = state.user
      const off = offset || 0
      const lim = limit || 10000
      if (data) {
        const Authorization = data.token_type + ' ' + data.access_token
        return await axios.get('/users/?skip=' + off + '&limit=' + lim, {
          headers: {
            Authorization
          }
        })
          .then((rsp) => {
            const vector = rsp.data
            for (let i = 0; i < vector.length; i++) {
              const obj = vector[i].extra_data
              for (const key in obj) {
                const value = obj[key]
                vector[i][key] = value
              }
              delete vector[i].extra_data
            }
            return vector
          })
          .catch(async (e) => {
            console.error('USERS', e)
            return false
          })
      }
    },
    // --Login
    async logIn ({ commit }, data) {
      // console.log('LOGIN DATA', data.get('username'))
      const rsp = await axios.post('/login/access-token', data)
        .then((rsp) => {
          commit('setUser', rsp.data)
          commit('setUserMail', data.get('username'))
          return rsp.data
        })
        .catch((e) => {
          console.error('LOGIN', e)
          return false
        })
      return rsp
    },
    // --Registration confirm
    async confirmUser ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/users/activate', payload, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          console.error('CONFIRM USER', e)
          return false
        })
      return rsp
    },
    // --Recover password
    async recoverPassword ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/password-recovery/' + payload.email, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          console.error('RESET PASSWORD', e)
          return false
        })
      return rsp
    },
    // --Reset password
    async resetPassword ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/reset-password', payload, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          console.error('RESET PASSWORD', e)
          return false
        })
      return rsp
    },
    // --Logout
    logout ({ commit }) {
      commit('setUser', null)
      commit('setUserMail', null)
    }
  },

  // ---MODULES
  modules: {}
})

store.subscribe((mutation, state) => {
  if (state.user) localStorage.setItem(process.env.VUE_APP_NAME + 'store', JSON.stringify(state.user))
  else localStorage.removeItem(process.env.VUE_APP_NAME + 'store')

  if (state.mail) localStorage.setItem(process.env.VUE_APP_NAME + 'currentMail', state.mail)
  else localStorage.removeItem(process.env.VUE_APP_NAME + 'currentMail')
})

export default store
