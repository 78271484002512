<template>
  <header id="main-header">
    <transition
      appear
      @before-enter="beforeEnter"
      @enter="enter"
    >
    <h2>
      <router-link
        to="/"
        @mouseenter="triggerAnim(true)"
        @mouseleave="triggerAnim(false)"
      >
        <b>Promotica</b>
        <v-icon name="bi-activity" :animation="anim" />Control Panel
      </router-link>
    </h2>
    </transition>
  </header>
  <transition name="alert">
    <aside
      class="main-alert"
      :class="{error : alertError}"
      v-if="alertMsg"
    >
      {{ alertMsg }}
    </aside>
  </transition>

  <router-view v-slot="{ Component }">
    <transition name="page">
      <component :is="Component" />
    </transition>
  </router-view>

  <footer id="main-footer">
    <small class="credits">
      Copyright © 2022 - Promotica S.p.A. - P.IVA e C.F.: 02394460980
      Sede in via Generale dalla Chiesa, 1 Desenzano del Garda (BS)
      Reg. Imp. Brescia n. 02394460980 - Capitale sociale: 800.000 €
      PEC: promotica@pec.promotica.it
    </small>
  </footer>
</template>

<script>
import { useStore } from 'vuex'
import gsap from 'gsap'

export default {
  setup () {
    const store = useStore()
    store.commit('initializeStore')
    const beforeEnter = (el) => {
      gsap.set(el, {
        opacity: 0,
        x: '-=10'
      })
    }
    const enter = (el) => {
      gsap.to(el, {
        delay: 0.4,
        duration: 1,
        opacity: 1,
        x: '0'
      })
    }
    return {
      beforeEnter,
      enter
    }
  },
  data () {
    return {
      anim: null,
      alertMsg: null,
      alertError: null
    }
  },
  methods: {
    triggerAnim (boolean) {
      if (boolean) this.anim = 'pulse'
      else this.anim = null
    }
  },
  mounted () {
    this.emitter.on('fireAlert', (data) => {
      this.alertMsg = data.msg
      this.alertError = data.error
      setTimeout(() => {
        this.alertMsg = null
        this.alertError = null
      }, 6000)
    })
  }
}
</script>
<style lang="scss">
  @import url('https://use.typekit.net/rjh2mos.css');
  @import "@/assets/scss/_mixins.scss";
  @import "@/assets/scss/app.scss";
</style>
